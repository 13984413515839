<template>
  <v-autocomplete
    ref="input"
    :items="searchValues"
    :loading="isLoading"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-no-data
    hide-selected
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    outlined
    item-text="name"
    :label="label"
    placeholder="Start typing to Search"
    clearable
    return-object
    @update:search-input="fetchSearchValues"
    @change="handleFieldChange"
    @click:clear="$emit('change', null)"
    @blur="$emit('blur')"
  />
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "UserSelectSearch",

  props: {
    value: {
      type: [String, Object],
      default: () => null,
    },
    selector: {
      type: Function,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => ["CLINICIAN"],
    },
    label: {
      type: String,
      default: "Clinician Name",
    },
    itemValue: {
      type: String,
      default: () => "",
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      searchValues: [],
    };
  },

  methods: {
    handleFieldChange(value) {
      this.$emit(
        "change",
        this.itemValue && value ? value[this.itemValue] : value
      );
    },
    fetchSearchValues: debounce(async function (value) {
      try {
        if (!value?.trim()) return (this.searchValues = []);
        this.isLoading = true;
        const select = "name";
        const filters = {
          name: { $regex: value, $options: "i" },
        };
        const { data } = await this.selector({
          select,
          filter: filters,
          pageSize: 50,
        });
        this.searchValues = data;
        this.loading = false;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.isLoading = false;
      }
    }, 1500),
    reset() {
      this.$refs.input.reset();
    },
  },
};
</script>
